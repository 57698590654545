<script>
// "compromises":
// 1. refresh will not save the checked filters
// 2. base query will not affect search filters

// "improvements as part of histogram feature":
// 1. Basequery is kept unaltered containing dates which is formed from advanced search when applying other date filters.

import { fetchContentTypes, fetchYearlyCounts } from '@/api'
import { replaceUrlQuery } from 'epmc-patterns/helpers'
import { registerMatomoEvent } from '@/helpers/matomo'
import { mapState } from 'vuex'
import { deepClone } from '@/helpers/search-filters'
import {
  getDefaultFilters,
  getChartConfig,
  SHARED_CHART_OPTIONS,
} from '@/config'
import nouislider from 'nouislider'
import 'nouislider/dist/nouislider.css'
import wNumb from 'wnumb'
import {
  Action,
  Loading,
  Notification,
  Tooltip,
  Chart,
  Modal,
} from 'epmc-patterns/components/v2'

const replaceLastOccurance = (str1, str2, str3) => {
  let newStr = str1

  const lastIndex = str1.lastIndexOf(str2)
  if (lastIndex !== -1) {
    newStr =
      newStr.substring(0, lastIndex) +
      str3 +
      newStr.substring(lastIndex + str2.length)
  }

  return newStr
}

const addOuterBrackets = (query) => {
  if (!query.includes(' OR ')) {
    return query
  } else {
    const hasOrBetweenConditions = checkForOrCondition(query) //add outer brackets only if 'OR' exists between experssions and not within an expression surrounded by brackets.
    if (
      query.startsWith('(') &&
      query.endsWith(')') &&
      hasOrBetweenConditions
    ) {
      //for cases like (FIRST_PDATE:[2000-03-01 TO 2024-04-09]) AND (SRC:PPR OR HAS_BOOK:Y)
      for (let i = 1; i < query.length - 1; i++) {
        if (query[i] === '(') {
          break
        } else if (query[i] === ')') {
          return '(' + query + ')'
        }
      }
      return query
    } else if (hasOrBetweenConditions) {
      return '(' + query + ')'
    }
  }
  return query
}

//compromise: (TITLE:"COVID 19" OR TITLE:"SORS CoV 2") OR (ABSTRACT:"COVID 19" OR ABSTRACT:"SARS CoV 2") AND (FIRST_PDATE:[1959 TO 2020])
const checkForOrCondition = (query) => {
  const regex = /\([^()]*\)/g
  const replacedQuery = query.replace(regex, (match) => {
    return match.replace(/\bor\b/gi, '@@@@')
  })
  if (replacedQuery.includes(' OR ')) return true
  return false
}

export default {
  components: { Action, Loading, Notification, Tooltip, Chart, Modal },
  props: {
    query: {
      type: String,
      default: '',
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      baseQuery: this.query,
      queryWithoutBaseQuery: '',
      currentYear: new Date().getFullYear(),
      filters: getDefaultFilters(),
      checkedFilters: {}, // {type: [querylist]}
      checkedSubFilters: {},
      lastUpdatedFilterType: '',
      loading: {
        Type: true,
        'Free full text access': true,
        'Date published': true,
      },

      startYear: '',
      endYear: '',
      showCustomDates: false,
      errorMessage: '',
      startYearInput: null,
      endYearInput: null,
      radioSelection: null,
      chart: getChartConfig(),
      modalChart: getChartConfig(),
      sharedChartOptions: SHARED_CHART_OPTIONS,
      chartOptions: {
        onClick: this.handleGraphClick,
        onHover: function (event) {
          const bar = this.getElementsAtEventForMode(event, 'nearest', {
            intersect: true,
          })
          this.chart.options.tooltips.intersect = bar.length ? true : false
        },
        layout: {
          padding: {
            left: -10,
            bottom: 7,
          },
        },
      },
      modalChartOptions: {
        onClick: this.handleModalGraphClick,
        onHover: function (event) {
          const bar = this.getElementsAtEventForMode(event, 'nearest', {
            intersect: true,
          })
          this.chart.options.tooltips.intersect = bar.length ? true : false
        },
        layout: {
          padding: {
            left: -10,
            bottom: 6,
          },
        },
      },
      modalVisible: false,
      sliderMin: null,
      sliderMax: null,
      sliderStart: null,
      sliderEnd: null,
      modalSliderStart: null,
      modalSliderEnd: null,
      fromCustomDates: false,
      isSliderMinUpdated: false,
      isSliderMaxUpdated: false,
      slider: null,
      modalSlider: null,
      isSlided: false,
      showSearchIcon: false,
      isRangeValid: true,
      isSliderRangeValid: true,
      handleDisplacement: 0,
      tooltipMargin: false,
      showResetTimeline: false,
      showResults: false,
      isModalResetClicked: false,
      showModalResetTimeline: false,
      graphClicked: false,
      modalGraphClicked: false,
      chartModalSelectedYear: null,
      showResultsFromModal: false,
      showResultsFromModalClicked: false,
      modalSliderChange: false,
      modalHandleValueStart: null,
      modalHandleValueEnd: null,
      isqueryWithoutBasequery: false,
    }
  },
  computed: {
    ...mapState('search', ['searchParams']),
  },
  watch: {
    query() {
      this.isCreated = false
      this.getFilters()
    },
  },
  created() {
    this.isCreated = true
    this.getFilters()
  },
  methods: {
    // rules for updating filters
    // 1. if the base query is changed, reset filters
    // 2. ensure that the update of a search filter will not update all of the same type
    // 3. when updating filters of a type, remove all queries for that type
    // 4. reduce the number of requests and reuse code if possible:
    // 5. uncheck disappeared years and 0 filters***
    getFilters() {
      const { loading, query, baseQuery, chart } = this
      //compromises for advanced search with date query affecting timeline graph - Query with OR between various conditions and having filter OR and subquery (ex: ((SRC:PPR AND HAS_VERSION_EVALUATIONS:Y) OR HAS_BOOK:Y))
      this.isqueryWithoutBasequery =
        baseQuery.includes('FIRST_PDATE') &&
        !checkForOrCondition(baseQuery) &&
        !this.checkMultipleNestedFilters()
      if (this.isqueryWithoutBasequery)
        this.queryWithoutBaseQuery = query.replace(this.baseQuery, ' ')
      else this.queryWithoutBaseQuery = query

      const queryWithoutBrackets = query.replace(/^(\(+)/, '') //trim leading brackets '(' (or) '(('' .. from query
      const baseQueryWithoutBrackets = baseQuery.replace(/^(\(+)/, '') //trim leading brackets '(' (or) '(('' .. from baseQuery

      //new condition to ignore brackets at the beginning of the query (there can be mutlipe brackets like ((SRC:*))
      if (!queryWithoutBrackets.startsWith(baseQueryWithoutBrackets)) {
        // if (!query.startsWith(baseQuery) && !query.startsWith('(' + baseQuery)) {
        this.baseQuery = query
        this.filters = getDefaultFilters()
        this.checkedFilters = {}
        this.lastUpdatedFilterType = ''
      }

      const { checkedFilters, filters, lastUpdatedFilterType } = this

      const removeQueriesByFilterType = (query, type) => {
        let newQuery = query
        if (checkedFilters[type]) {
          let queries = ''
          checkedFilters[type].forEach((q, index) => {
            if (index !== 0) {
              queries += ' OR '
            }
            queries += q
          })
          newQuery = replaceLastOccurance(
            newQuery,
            ' AND (' + queries + ')',
            ''
          )
        }
        return newQuery
      }

      if (
        (!lastUpdatedFilterType ||
          lastUpdatedFilterType === 'Date published') &&
        !checkedFilters['Type'] &&
        !checkedFilters['Free full text access']
      ) {
        loading['Type'] = true
        loading['Free full text access'] = true
        this.getContentTypes(query).then((contentResponse) => {
          loading['Type'] = false
          loading['Free full text access'] = false
          if (contentResponse) {
            const counts = [
              ...contentResponse.profileList.pubType,
              ...contentResponse.profileList.source,
            ]
            const filtersArr = [
              ...filters['Type'],
              ...filters['Free full text access'],
            ]
            filtersArr.forEach((filter) => {
              const type = counts.find((t) => t.name === filter.name)
              if (type) {
                filter.count = type.count
              }
            })
            if (!this.isSlided)
              // dont update the slider handle if its from dragging the slider by user
              this.updateSliderHandle()
            else this.isSlided = false //else if its dragged by user, reset to initial state
            this.showSlider('slider') // the fix the issue of slider not being intialized after refreshed from the results of modal
          }
        })
        this.isRangeValid = true //resetting the isRangeValid to default
      } else {
        if (!lastUpdatedFilterType || lastUpdatedFilterType !== 'Type') {
          const typesQuery = removeQueriesByFilterType(query, 'Type')
          loading['Type'] = true
          this.getContentTypes(typesQuery).then((contentResponse) => {
            loading['Type'] = false
            if (contentResponse) {
              const counts = [
                ...contentResponse.profileList.pubType,
                ...contentResponse.profileList.source,
              ]
              const filtersArr = [...filters['Type']]
              filtersArr.forEach((filter) => {
                const type = counts.find((t) => t.name === filter.name)
                if (type) {
                  filter.count = type.count
                }
              })
            }
          })
          if (!this.isSlided)
            // dont update the slider handle if its from dragging the slider by user
            this.updateSliderHandle()
          else this.isSlided = false // else if its pulled by user, reset to initial state
          this.isRangeValid = true // resetting the isRangeValid to default
          this.showSlider('slider') // to fix the issue of slider not being intialized after refreshed from the results of modal
        }

        if (
          !lastUpdatedFilterType ||
          lastUpdatedFilterType !== 'Free full text access'
        ) {
          const typesQuery = removeQueriesByFilterType(
            query,
            'Free full text access'
          )
          loading['Free full text access'] = true
          this.getContentTypes(typesQuery).then((contentResponse) => {
            loading['Free full text access'] = false
            if (contentResponse) {
              const counts = [...contentResponse.profileList.pubType]
              const filtersArr = [...filters['Free full text access']]
              filtersArr.forEach((filter) => {
                const type = counts.find((t) => t.name === filter.name)
                if (type) {
                  filter.count = type.count
                }
              })
            }
          })
          if (!this.isSlided)
            // don't update the slider handle if its from dragging the slider
            this.updateSliderHandle()
          else this.isSlided = false // else if its dragged, reset to initial state
          this.isRangeValid = true // resetting the isRangeValid to default
          this.showSlider('slider') // to fix the issue of slider not being intialized after refreshed from the results of modal
        }
      }

      if (
        !lastUpdatedFilterType ||
        lastUpdatedFilterType !== 'Date published'
      ) {
        chart.update = false
        const yearsQuery = removeQueriesByFilterType(query, 'Date published')
        loading['Date published'] = true
        this.getYears(yearsQuery).then((yearsResponse) => {
          loading['Date published'] = false
          if (yearsResponse && Object.keys(yearsResponse).length !== 0) {
            const filtersArr = [...filters['Date published']]
            filtersArr.forEach((filter) => {
              if (filter.name !== 'Custom date filter') {
                filter.count = this.getCountInRange(
                  yearsResponse,
                  filter.startYear,
                  filter.endYear
                )
              }
            })
            // if its moved up, it collapes with the chart data
            this.setChartData(yearsResponse)
            this.showSlider('slider')
            if (!this.query.includes('FIRST_PDATE')) {
              // to address CIT-9497 [ reset behaviour on enabling filters ]
              this.sliderStart = this.sliderMin
              this.sliderEnd = this.sliderMax
            }
            if (!this.isCreated) {
              if (this.isSliderMinUpdated || this.isSliderMaxUpdated) {
                this.updateSliderRange()
              }
            }
            // set isRangeValid flag after every years api fetch
            this.setIsRangeValid()
            if (Object.keys(yearsResponse).length === 1)
              this.isSliderRangeValid = false
            else this.isSliderRangeValid = true
          } else {
            this.isRangeValid = false
          }
        })
      }
    },
    async onContentFilterChange(e) {
      this.showSearchIcon = false // whenever a filter is applied, the show search can disapear
      const filterType = e.target.name
      const filterIndex = e.target.value
      const filter = this.filters[filterType][filterIndex]
      const { checkedFilters, query, checkedSubFilters } = this
      let filterQuery = filter.query
      let newQuery = query

      // if date filters are checked, remove custom dates fields
      if (filterType === 'Date published') {
        if (filter.name === 'Custom date filter') {
          this.fromCustomDates = true
          return
        } else {
          // show reset timeline link
          if (!this.showResetTimeline) this.showResetTimeline = true
          // whenever a date filter is applied when the custom filter is on, the custom date range can be cleared.
          this.clearCustomDates()
          this.fromCustomDates = false

          //update timeline graph and slider
          if (filter.name.includes('last')) {
            const patternToMatch = /\[(\d{4}) TO (\d{4})\]/
            const years = filter.query.match(patternToMatch)
            if (years && years.length >= 3) {
              //update timeline graph and slider handle
              this.transitionSlider(parseInt(years[1]), parseInt(years[2]))
            }
          }
        }
        // remove any checked filters for the same type
        delete checkedFilters[filterType]
        // better remove the last occurrence
        newQuery = this.queryWithoutBaseQuery //CIT-9506
        newQuery = newQuery.replace(
          / AND \(FIRST_PDATE:([^)])+TO([^)])+\)/g,
          ''
        )
        if (this.isqueryWithoutBasequery)
          // remove FIRST_PDATE from the query without baseQuery and update the query - CIT-9506
          newQuery = this.baseQuery + ' ' + newQuery.trim() //CIT-9506
      }
      // update lastUpdatedFilterType
      this.lastUpdatedFilterType = filterType

      // if the filter is checked
      if (
        filter.checked ||
        (filterType === 'Date published' && this.radioSelection != null)
      ) {
        // if there is a filter of the same type
        if (checkedFilters[filterType]) {
          // if this filter has a parent, add it to the parent query with AND
          if (filter.parent) {
            const parentQuery =
              this.filters[filterType][filter.parent.index].query
            //if there is a subfilter of the parent applied, replace the query to include 'OR' of subfilters
            if (checkedSubFilters[filter.parent.name]) {
              // remove previous queries with subfilters
              let lastFilterQuery = ''
              if (checkedSubFilters[filter.parent.name].length > 1)
                lastFilterQuery =
                  '(' +
                  parentQuery +
                  ' AND (' +
                  checkedSubFilters[filter.parent.name].join(' OR ') +
                  '))'
              else
                lastFilterQuery =
                  '(' +
                  parentQuery +
                  ' AND ' +
                  checkedSubFilters[filter.parent.name] +
                  ')'
              const replacementQuery =
                '(' +
                parentQuery +
                ' AND (' +
                checkedSubFilters[filter.parent.name].join(' OR ') +
                ' OR ' +
                filterQuery +
                '))'
              newQuery = replaceLastOccurance(
                newQuery,
                lastFilterQuery,
                replacementQuery
              )
              const index = checkedFilters[filterType].indexOf(lastFilterQuery)
              checkedFilters[filterType].splice(index, 1)
              checkedSubFilters[filter.parent.name].push(filterQuery)
              // change filter query to have the new query
              filterQuery = replacementQuery
            } else {
              newQuery = replaceLastOccurance(
                newQuery,
                parentQuery,
                '(' + parentQuery + ' AND ' + filterQuery + ')'
              )
              checkedSubFilters[filter.parent.name] = [filterQuery]
              // change filter query to have the parent query
              filterQuery = '(' + parentQuery + ' AND ' + filterQuery + ')'
              // remove parent query from checked queries
              const index = checkedFilters[filterType].indexOf(parentQuery)
              checkedFilters[filterType].splice(index, 1)
            }
          } else {
            let _filterQuery = filterQuery
            let _newQuery = newQuery
            let lastFilterQuery =
              checkedFilters[filterType][checkedFilters[filterType].length - 1]
            if (filterQuery === '(HAS_FREE_FULLTEXT:Y NOT HAS_FT:Y)') {
              _filterQuery = '(HAS_FREE_FULLTEXT:Y)'
            } else if (filterQuery === 'HAS_FT:Y') {
              _newQuery = _newQuery.replace(
                'HAS_FREE_FULLTEXT:Y NOT HAS_FT:Y',
                'HAS_FREE_FULLTEXT:Y'
              )
              lastFilterQuery = '(HAS_FREE_FULLTEXT:Y)'
            }
            newQuery = replaceLastOccurance(
              _newQuery,
              lastFilterQuery,
              lastFilterQuery + ' OR ' + _filterQuery
            )
          }
          checkedFilters[filterType].push(filterQuery)
        } else {
          newQuery = addOuterBrackets(newQuery)
          newQuery += ' AND (' + filterQuery + ')'
          checkedFilters[filterType] = [filterQuery]
        }
        //this.changeQuery(newQuery) - check this we are removing this and adding the below
        //avoid redundant navigation
        // await asynchronously while slider animates
        if (filterType === 'Date published' && filter.name.includes('last'))
          //to avoid when clicking on filters other than dates
          await new Promise((resolve) => setTimeout(resolve, 500)) // Introduce a delay to make the animated slider handle movement visible
        if (query !== newQuery) this.changeQuery(newQuery)

        const eventTypeMapper = {
          Type: 'content type',
          'Free full text access': 'full text availability',
          'Date published': 'date',
        }
        registerMatomoEvent(
          'Search',
          'Filter - ' + eventTypeMapper[filterType],
          filter.parent
            ? this.filters[filterType][filter.parent.index].label +
                ' - ' +
                filter.label
            : filter.label
        )
        if (filterType != 'Date published') {
          registerMatomoEvent(
            'Search',
            'Filter - ' + eventTypeMapper[filterType],
            filter.label
          )
        }
      } else {
        let replacement = ''
        let parentQuery = ''
        // handle if the unselected is a child, remove the parent AND child, then overwrite with paren query
        if (filter.parent) {
          parentQuery = this.filters[filterType][filter.parent.index].query
          if (checkedSubFilters[filter.parent.name].length === 1) {
            // only 1 child/subfilter
            delete checkedSubFilters[filter.parent.name]
            replacement = parentQuery
            filterQuery = '(' + parentQuery + ' AND ' + filterQuery + ')' // applicable only if there is one selected child
          } else {
            filterQuery =
              '(' +
              parentQuery +
              ' AND (' +
              checkedSubFilters[filter.parent.name].join(' OR ') +
              '))'
            checkedSubFilters[filter.parent.name].splice(
              checkedSubFilters[filter.parent.name].indexOf(filter.query),
              1
            )
            if (checkedSubFilters[filter.parent.name].length === 1)
              replacement =
                '(' +
                parentQuery +
                ' AND ' +
                checkedSubFilters[filter.parent.name] +
                ')'
            else
              replacement =
                '(' +
                parentQuery +
                ' AND (' +
                checkedSubFilters[filter.parent.name].join(' OR ') +
                '))'
          }
        }
        // if the unselected has a child, check that this child is selected. If it is, remove it from checked filters and make it unchecked
        // and remove the query parent AND child
        else if (filter.subfilter) {
          filter.subfilter.forEach((subFilter) => {
            if (this.filters[filterType][subFilter.index].checked) {
              const subQuery = this.filters[filterType][subFilter.index].query
              this.filters[filterType][subFilter.index].checked = false
              if (checkedSubFilters[filter.name].length === 1)
                filterQuery = '(' + filterQuery + ' AND ' + subQuery + ')'
            }
          })
          if (
            checkedSubFilters[filter.name] &&
            checkedSubFilters[filter.name].length > 1
          )
            filterQuery =
              '(' +
              filter.query +
              ' AND (' +
              checkedSubFilters[filter.name].join(' OR ') +
              '))'
          delete checkedSubFilters[filter.name]
        }
        const index = checkedFilters[filterType].indexOf(filterQuery)
        const length = checkedFilters[filterType].length
        // if the replaced query is a child query, needs to be replaced by its parent query instead of removing the query completely
        // if filterQuery is the only in the same type
        if (length === 1) {
          newQuery = replaceLastOccurance(
            newQuery,
            ' AND (' + filterQuery + ')',
            replacement ? ' AND (' + replacement + ')' : ''
          )
        } else {
          if (index === 0) {
            let _filterQuery = filterQuery
            let _newQuery = newQuery
            if (filterQuery === '(HAS_FREE_FULLTEXT:Y NOT HAS_FT:Y)') {
              _filterQuery = '(HAS_FREE_FULLTEXT:Y)'
            } else if (filterQuery === 'HAS_FT:Y') {
              _newQuery = _newQuery.replace(
                'HAS_FREE_FULLTEXT:Y',
                'HAS_FREE_FULLTEXT:Y NOT HAS_FT:Y'
              )
            }
            if (newQuery.includes(_filterQuery + ' OR '))
              newQuery = replaceLastOccurance(
                _newQuery,
                _filterQuery + ' OR ',
                replacement ? replacement + ' OR ' : ''
              )
            else
              newQuery = replaceLastOccurance(
                _newQuery,
                ' OR ' + _filterQuery,
                replacement ? ' OR ' + replacement : ''
              )
          } else {
            let _filterQuery = filterQuery
            let _newQuery = newQuery
            if (filterQuery === '(HAS_FREE_FULLTEXT:Y NOT HAS_FT:Y)') {
              _filterQuery = '(HAS_FREE_FULLTEXT:Y)'
            } else if (filterQuery === 'HAS_FT:Y') {
              _newQuery = _newQuery.replace(
                'HAS_FREE_FULLTEXT:Y',
                'HAS_FREE_FULLTEXT:Y NOT HAS_FT:Y'
              )
            }
            if (newQuery.includes(' OR ' + _filterQuery))
              newQuery = replaceLastOccurance(
                _newQuery,
                ' OR ' + _filterQuery,
                replacement ? ' OR ' + replacement : ''
              )
            else
              newQuery = replaceLastOccurance(
                _newQuery,
                _filterQuery + ' OR ',
                replacement ? replacement + ' OR ' : ''
              )
          }
        }
        // if the unselected is the child query
        if (filter.parent) {
          if (
            checkedSubFilters[filter.parent.name] &&
            checkedSubFilters[filter.parent.name].length > 0
          )
            checkedFilters[filterType][index] = replacement
          else checkedFilters[filterType][index] = parentQuery
        } else {
          checkedFilters[filterType].splice(index, 1)
        }
        if (checkedFilters[filterType].length === 0) {
          delete checkedFilters[filterType]
        }
        this.changeQuery(newQuery)
      }
    },
    async searchCustomDates(startYear, endYear) {
      if (startYear && endYear) {
        this.startYear = startYear
        this.endYear = endYear
      } else {
        this.startYear = document.getElementById(
          'search-results--filter--dateFrom'
        ).value
        this.endYear = document.getElementById(
          'search-results--filter--dateTo'
        ).value
      }
      this.radioSelection = 3
      let errorMessage = ''

      if (!/^\d{4}$/.test(this.startYear)) {
        errorMessage = 'Please enter a valid starting year.'
      } else if (!/^\d{4}$/.test(this.endYear)) {
        errorMessage = 'Please enter a valid ending year.'
      } else if (this.startYear > this.endYear) {
        errorMessage = 'The starting year should be smaller.'
      }
      if (!errorMessage) {
        if (!this.showResetTimeline) this.showResetTimeline = true
        if (!this.isSlided && !startYear) {
          //update timeline graph and slider handles on custom date input search
          this.transitionSlider(this.startYear, this.endYear)
          // register matomo event for custom date search only upon entered by user
          registerMatomoEvent(
            'Search',
            'Filter - custom date entry fields ',
            'Date entry - [' + this.startYear + ' - ' + this.endYear + ']'
          )
        }
        const { query, queryWithoutBaseQuery } = this

        let newQuery = addOuterBrackets(query)

        delete this.checkedFilters['Date published']
        this.lastUpdatedFilterType = ''

        // CIT-9506 - remove baseQuery from query and check for dates instead of directly checking from query to fix the page refresh issue when dates are searched from advanced search by keeping the whole basequery static
        // const found = query.match(/ AND \(FIRST_PDATE:[^)]+\)/g)
        const found = queryWithoutBaseQuery.match(/ AND \(FIRST_PDATE:[^)]+\)/g) //CIT-9506
        // if query contains the query for dates
        if (found) {
          // newQuery = query.replace(
          //   / AND \(FIRST_PDATE:[^)]+\)/g,
          //   ' AND (FIRST_PDATE:[' +
          //     this.startYear +
          //     ' TO ' +
          //     this.endYear +
          //     '])'
          // )
          newQuery = queryWithoutBaseQuery.replace(
            //CIT-9506
            / AND \(FIRST_PDATE:[^)]+\)/g,
            ' AND (FIRST_PDATE:[' +
              this.startYear +
              ' TO ' +
              this.endYear +
              '])'
          )
          // combine baseQuery and newQuery - CIT-9506
          if (this.isqueryWithoutBasequery)
            // remove FIRST_PDATE from the query without baseQuery and update the query - CIT-9506
            newQuery = this.baseQuery + ' ' + newQuery.trim()
        } else {
          newQuery +=
            ' AND (FIRST_PDATE:[' +
            this.startYear +
            ' TO ' +
            this.endYear +
            '])'
        }

        // await asynchronously while slider animates
        if (
          (!this.isSlided && !startYear) ||
          this.graphClicked ||
          this.showResultsFromModalClicked
        ) {
          //to avoid when not called from custom input search and graph click
          await new Promise((resolve) => setTimeout(resolve, 500)) // Introduce a delay to make the animated slider handle movement visible
          this.graphClicked = false
          this.showResultsFromModalClicked = false
        }
        this.changeQuery(newQuery)

        if (this.isSlided) {
          //don't update if this is not called from dragging the slider
          this.sliderStart = this.startYear
          this.sliderEnd = this.endYear
        }
        this.fromCustomDates = true
        this.showSearchIcon = false

        this.checkedFilters['Date published'] = [
          'FIRST_PDATE:[' + this.startYear + ' TO ' + this.endYear + ']',
        ]
      }
      this.errorMessage = errorMessage
    },
    clearCustomDates() {
      this.startYear = ''
      this.endYear = ''
      this.startYearInput = null
      this.endYearInput = null
      this.errorMessage = ''
    },
    showTooltip(filterType) {
      registerMatomoEvent(
        'Search',
        'Filter - ' + filterType === 'Type'
          ? 'content type'
          : 'full text availability',
        'Tooltip accessed'
      )
    },
    showCustomDatesPane() {
      this.showCustomDates = !this.showCustomDates
      if (this.showCustomDates) {
        registerMatomoEvent('Search', 'Filter - custom date range')
      }
    },
    // for unit tests
    getContentTypes(query) {
      return fetchContentTypes(query)
    },
    getYears(query) {
      return fetchYearlyCounts(query)
    },
    changeQuery(newQuery) {
      this.$router.push({
        path:
          'betaSearch' +
          replaceUrlQuery({
            query: newQuery,
            page: 1,
            maxPageJump: '',
          }),
      })
    },
    // Histogram search feature methods - start
    onCustomInputStart(event) {
      this.startYearInput = event.target.value
      this.radioSelection = 3
      this.showSearchIcon = true
    },
    onCustomInputEnd(event) {
      this.endYearInput = event.target.value
      this.radioSelection = 3
      this.showSearchIcon = true
    },
    getCountInRange(data, startYear, endYear) {
      return data
        .filter((item) => item.year >= startYear && item.year <= endYear)
        .reduce((total, item) => total + item.count, 0)
    },
    getRangeOfYearFromQuery() {
      const patternToMatch = /\[(\d{4}) TO (\d{4})\]/
      if (this.queryWithoutBaseQuery) {
        // CIT-9506
        const years = this.queryWithoutBaseQuery.match(patternToMatch)
        return years
      } else if (this.query && this.query != null) {
        const years = this.query.match(patternToMatch)
        return years
      }
      // if (this.query && this.query != null) {
      //   const patternToMatch = /\[(\d{4}) TO (\d{4})\]/
      //   const years = this.query.match(patternToMatch)
      //   return years
      // }
    },
    setIsRangeValid() {
      if (
        this.startYear &&
        this.endYear &&
        this.sliderMin &&
        this.sliderMax &&
        !(
          (this.startYear <= this.sliderMax &&
            this.endYear >= this.sliderMin) ||
          (this.sliderMin <= this.endYear && this.sliderMax >= this.startYear)
        )
      )
        this.isRangeValid = false
      else this.isRangeValid = true
    },

    //slider methods - start
    showSlider(sliderType) {
      if (
        //don't show any slider when there is only one bar
        this.sliderMin &&
        this.sliderMax &&
        this.sliderMin == this.sliderMax
      )
        return
      let sliderElement = ''
      if (this.sliderMin != null && this.sliderMax != null) {
        if (sliderType && sliderType == 'slider') {
          sliderElement = this.$refs.slider[0]
          if (this.slider) this.slider.destroy()
        } else {
          sliderElement = this.$refs.modalSlider
          if (this.modalSlider) this.modalSlider.destroy()
        }
        this.createSlider(sliderElement, sliderType)
      }
    },
    createSlider(sliderElement, sliderType) {
      this[sliderType] = nouislider.create(sliderElement, {
        start: [this.sliderStart, this.sliderEnd],
        connect: true,
        step: 0.1,
        animate: true,
        animationDuration: 500,
        range: {
          min: this.sliderMin,
          max: this.sliderMax,
        },
        behaviour: 'tap-drag',
        tooltips: true,
        format: wNumb({ decimals: 0 }),
        keyboardSupport: true,
        keyboardDefaultStep: 1,
      })
      if (this.slider && sliderType === 'slider') this.setSliderEvents()
      else if (this.modalSlider && sliderType === 'modalSlider')
        this.setModalSliderEvents()
    },
    setSliderEvents() {
      let timeout = null
      let sliderChanged = false
      this.slider.on('slide', (values, handle) => {
        if (!this.showResetTimeline) this.showResetTimeline = true
        sliderChanged = true //to prevent the change event being called on handle click rather than slide
        //update startYear and endYear as the slider handle changes
        this.startYearInput = null
        this.endYearInput = null
        this.startYear = values[0]
        this.endYear = values[1]
        /* whenever the slider handles are moved, updated the bar chart colors 
           & set radio selection to custom dates whenever there is a value populated */
        this.radioSelection = 3
        this.chart.update = false
        this.updateChartBarColorsWithOpacity(
          'chart',
          this.startYear,
          this.endYear
        )
        setTimeout(() => {
          this.handleSliderTooltipOverlap(values, handle, true, 'slider')
        }, 100)
      })
      this.slider.on('change', () => {
        if (sliderChanged) {
          // to prevent the change event being called on handle click rather than slide
          clearTimeout(timeout)
          timeout = setTimeout(() => {
            this.handleSliderChange()
          }, 100)
          if (
            this.sliderMin == this.sliderStart &&
            this.sliderMax == this.sliderEnd
          ) {
            //to disable reset when the slider is dragged back to initial years
            this.showResetTimeline = false
          } else {
            this.showResetTimeline = true
          }
          registerMatomoEvent(
            'Search',
            'Filter - timeline handle move',
            'Timeline handle'
          )
        }
        sliderChanged = false
        this.tooltipMargin = false
      })
      this.slider.on('update', (values, handle) => {
        /* update and slide are almost for same functionality with one difference:
          update - triggered when the slider's value is changed either by user interaction or programmatically; 
          slide - trigger only on user interaction */
        setTimeout(() => {
          this.handleSliderTooltipOverlap(values, handle, false, 'slider') // to prevent slider handle tootip from being overlapped on close proximity
        }, 100)
        if (
          this.sliderMin == this.sliderStart &&
          this.sliderMax == this.sliderEnd
        ) {
          //to disable reset when the slider is dragged back to initial years
          this.showResetTimeline = false
        } else {
          this.showResetTimeline = true
        }
      })
    },
    handleSliderTooltipOverlap(values, handle, fromSliderChange, sliderType) {
      let sliderRect = null
      let lowerHandle = null
      let upperHandle = null
      const adjustTooltips = () =>
        new Promise((resolve) => {
          setTimeout(() => {
            requestAnimationFrame(() => {
              const slidingHandleValue = parseFloat(values[handle])
              if (sliderType === 'slider') {
                sliderRect = this.$refs.slider[0].getBoundingClientRect()
                lowerHandle =
                  this.$refs.slider[0].querySelector('.noUi-handle-lower')
                upperHandle =
                  this.$refs.slider[0].querySelector('.noUi-handle-upper')
              } else {
                sliderRect = this.$refs.modalSlider.getBoundingClientRect()
                lowerHandle =
                  this.$refs.modalSlider.querySelector('.noUi-handle-lower')
                upperHandle =
                  this.$refs.modalSlider.querySelector('.noUi-handle-upper')
              }
              const lowerHandleRect = lowerHandle.getBoundingClientRect()
              const upperHandleRect = upperHandle.getBoundingClientRect()
              const lowerHandlePosition = lowerHandleRect.left - sliderRect.left
              const upperHandlePosition = upperHandleRect.left - sliderRect.left
              const handleDistance = Math.abs(
                upperHandlePosition - lowerHandlePosition
              )
              const isIntersecting =
                handleDistance != 0 &&
                handleDistance < 40 &&
                values[0] !== values[1]
              lowerHandle
                .querySelector('.noUi-tooltip')
                .classList.toggle('intersected', isIntersecting)
              upperHandle
                .querySelector('.noUi-tooltip')
                .classList.remove('hide')
              // Check proximity and adjust tooltip positions
              if (isIntersecting) {
                this.handleDisplacement =
                  40 - Math.abs(upperHandlePosition - lowerHandlePosition)
                if (fromSliderChange) {
                  if (slidingHandleValue == values[0]) {
                    // Calculate the maximum allowable tooltip position
                    if (
                      upperHandle
                        .querySelector('.noUi-tooltip')
                        .getBoundingClientRect().right <= sliderRect.right
                    ) {
                      lowerHandle.querySelector(
                        '.noUi-tooltip.intersected'
                      ).style.transform = `translateX(${-this
                        .handleDisplacement}px)`
                      upperHandle.querySelector(
                        '.noUi-tooltip'
                      ).style.transform = `none`
                    } else {
                      upperHandle.querySelector(
                        '.noUi-tooltip'
                      ).style.transform = `translateX(0px)`
                      lowerHandle.querySelector(
                        '.noUi-tooltip.intersected'
                      ).style.transform = `translateX(${-this
                        .handleDisplacement}px)`
                    }
                  } else if (slidingHandleValue == values[1]) {
                    if (
                      upperHandle
                        .querySelector('.noUi-tooltip')
                        .getBoundingClientRect().right >= sliderRect.right
                    ) {
                      // even if upper handle of slider is moved, sometimes the upper handle may be out of bounds at the right side
                      this.tooltipMargin = true
                    } else if (!this.tooltipMargin) {
                      if (
                        lowerHandle
                          .querySelector('.noUi-tooltip.intersected')
                          .getBoundingClientRect().left >= sliderRect.left
                      ) {
                        upperHandle.querySelector(
                          '.noUi-tooltip'
                        ).style.transform = `translateX(${this.handleDisplacement}px)`
                        lowerHandle.querySelector(
                          '.noUi-tooltip.intersected'
                        ).style.transform = `none`
                      } else {
                        lowerHandle.querySelector(
                          '.noUi-tooltip.intersected'
                        ).style.transform = `translateX(0px)`
                        upperHandle.querySelector(
                          '.noUi-tooltip'
                        ).style.transform = `translateX(${this.handleDisplacement}px)`
                      }
                    }
                  }
                } else {
                  //slider changed programmatically
                  upperHandle.querySelector(
                    '.noUi-tooltip'
                  ).style.transform = `translateX(${this.handleDisplacement}px)`
                  if (
                    upperHandle
                      .querySelector('.noUi-tooltip')
                      .getBoundingClientRect().right >= sliderRect.right
                  ) {
                    upperHandle.querySelector(
                      '.noUi-tooltip'
                    ).style.transform = `none`
                    lowerHandle.querySelector(
                      '.noUi-tooltip.intersected'
                    ).style.transform = `translateX(${-this
                      .handleDisplacement}px)`
                  }
                }
              } else if (values[0] == values[1]) {
                upperHandle.querySelector('.noUi-tooltip').classList.add('hide')
                lowerHandle.querySelector(
                  '.noUi-tooltip'
                ).style.transform = `none`
              }
              resolve()
            })
          }, 100)
        })
      adjustTooltips().then(() => {
        //console.log("Adjustments completed");
      })
    },
    updateSliderHandle() {
      const { checkedFilters } = this
      // update the start and end of slider range to the start and end year in the query for the corrsponding date filter
      if (checkedFilters['Date published']) {
        // to avoid undefined error during creation of slider
        const years = this.getRangeOfYearFromQuery()
        if (years && years.length >= 3) {
          const startYear = parseInt(years[1])
          const endYear = parseInt(years[2])
          if (
            this.fromCustomDates ||
            this.sliderStart != startYear ||
            this.sliderEnd != endYear
          ) {
            // no updates on slecting date filters to custom range field
            this.sliderStart = startYear
            this.sliderEnd = endYear
            this.updateSliderRange()
          }
        }
      }
    },
    updateSliderRange() {
      this.slider.updateOptions({
        range: {
          min: this.sliderMin,
          max: this.sliderMax,
        },
        start: [this.sliderStart, this.sliderEnd],
      })
      /*whenever the slider handles are updated, updated the bar chart colors*/
      this.chart.update = false
      this.updateChartBarColors()
      if (this.isSliderMinUpdated && this.sliderMin == this.sliderStart) {
        // to handle the state when applying SRC:PPR which changes range to 2011 to 2023; custom date only to be updated when the start and end of handles are matching min and max of slider
        this.startYear = this.sliderMin
        if (!this.endYear) this.endYear = this.sliderMax // initial state when applying SRC:PPR which changes only start year where end year will be empty
        this.radioSelection = 3
      }
      if (this.isSliderMaxUpdated && this.slideMax == this.sliderEnd) {
        this.endYear = this.sliderMax
        if (!this.startYear) this.startYear = this.sliderMin
        this.radioSelection = 3
      }
      this.isSliderMinUpdated = false
      this.isSliderMaxUpdated = false
    },
    handleSliderChange() {
      this.isSlided = true
      this.searchCustomDates(null, null)
    },
    setModalSliderEvents() {
      this.modalSlider.on('update', (values, handle) => {
        setTimeout(() => {
          this.handleSliderTooltipOverlap(values, handle, true, 'modalSlider')
        }, 100)
      })
      this.modalSlider.on('slide', (values) => {
        setTimeout(() => {
          this.modalHandleValueStart = values[0]
          this.modalHandleValueEnd = values[1]
          this.modalSliderChange = true
          this.showResults = true
          this.showModalResetTimeline = true
          if (this.modalGraphClicked) this.modalGraphClicked = false
          this.modalChart.update = false
          this.updateChartBarColorsWithOpacity(
            'modalChart',
            values[0],
            values[1]
          )
        }, 100)
      })
      this.modalSlider.on('change', (values) => {
        registerMatomoEvent(
          'Search',
          'Filter - big timeline handle',
          'BigTimeline handle'
        )
        // logic to hide reset and showresult button if the slider is dragged back to initial values
        this.modalSliderStart = values[0]
        this.modalSliderEnd = values[1]
        if (
          this.sliderMin == this.modalSliderStart &&
          this.sliderMax == this.modalSliderEnd &&
          !this.showResetTimeline
        ) {
          this.showModalResetTimeline = false
          this.showResults = false
        } else {
          this.showModalResetTimeline = true
          this.showResults = true
        }
      })
    },
    setSliderValue(minLabelValue, maxLabelValue) {
      if (this.sliderMin == null || this.sliderMin != minLabelValue) {
        if (this.sliderMin != null && this.sliderMin != minLabelValue) {
          this.isSliderMinUpdated = true
        }
        this.sliderMin = minLabelValue
        if (!this.checkedFilters['Date published'])
          // if the date filter is already applied, we only need to change min and max values and not handler values
          this.sliderStart = this.sliderMin
      }
      if (this.sliderMax == null || this.sliderMax != maxLabelValue) {
        if (this.sliderMax != null && this.sliderMax != maxLabelValue) {
          this.isSliderMaxUpdated = true
        }
        this.sliderMax = maxLabelValue
        if (!this.checkedFilters['Date published'])
          // if the date filter is already applied, we only need to change min and max values and not handler values
          this.sliderEnd = this.sliderMax
      }
    },
    transitionSlider(startYear, endyear) {
      this.sliderStart = startYear
      this.sliderEnd = endyear
      this.$refs.slider[0].noUiSlider.set([startYear, endyear]) // enable smooth animation of slider handle change
      this.chart.update = false
      this.updateChartBarColors()
    },
    //slider methods - end

    //timeline chart methods - start
    setChartData(yearsResponse) {
      const { chart } = this
      try {
        let response = yearsResponse
        response = response.reverse()

        //Generate labels from response
        const minYear = parseInt(response[0].year)
        const maxYear = parseInt(response[response.length - 1].year)
        // Generate an array of years covering the range from min to max
        const allYears = Array.from(
          { length: maxYear - minYear + 1 },
          (_, index) => (minYear + index).toString()
        )
        chart.labels = allYears
        // chart.labels = response.map((range) => range.year)

        if (!chart.datasets[0].backgroundColor) {
          chart.datasets[0].backgroundColor = new Array(
            chart.labels.length
          ).fill('#79B4DE')
          chart.datasets[0].borderColor = new Array(chart.labels.length).fill(
            '#79B4DE'
          )
          chart.datasets[0].hoverBackgroundColor = new Array(
            chart.labels.length
          ).fill('#3C7EAD')
        }

        // Generate data from response - Create a map from years to counts for fast lookup
        const yearToCountMap = {}
        response.forEach(({ year, count }) => {
          yearToCountMap[year] = count
        })
        chart.datasets[0].data = allYears.map(
          (year) => yearToCountMap[year] || 0
        )
        // chart.datasets[0].data = response.map((range) => range.count)
        if (!chart.update) chart.update = true
        //set the min and max values only on initial state or when the values change
        const minLabelValue = Number(chart.labels[0])
        const maxLabelValue = Number(chart.labels[chart.labels.length - 1])
        if (minLabelValue != null && maxLabelValue != null)
          this.setSliderValue(minLabelValue, maxLabelValue)
      } catch (e) {}
    },
    updateChartBarColorsWithOpacity(chartType, startYear, endYear) {
      // Reset bar colors to default color
      this[chartType].datasets[0].backgroundColor = this[
        chartType
      ].datasets[0].data.map(() => '#79B4DE')
      this[chartType].datasets[0].borderColor = this[
        chartType
      ].datasets[0].data.map(() => '#79B4DE')
      this[chartType].datasets[0].hoverBackgroundColor = this[
        chartType
      ].datasets[0].data.map(() => '#3C7EAD')

      // Calculate fade-out range
      const fadeStartIndex =
        this[chartType].labels.indexOf((startYear - 1).toString()) + 1
      const fadeEndIndex =
        this[chartType].labels.indexOf((endYear + 1).toString()) - 1

      // Apply fade-out effect to non-slided range
      for (let i = 0; i < this[chartType].labels.length; i++) {
        const year = parseInt(this[chartType].labels[i])
        if (year >= startYear && year <= endYear) {
          this[chartType].datasets[0].backgroundColor[i] = '#79B4DE' // Color for slided range
        } else if (i >= fadeStartIndex && i <= fadeEndIndex) {
          const opacity =
            1 -
            Math.abs(year - (startYear + endYear) / 2) /
              (endYear - startYear + 2)
          this[chartType].datasets[0].backgroundColor[
            i
          ] = `rgba(204, 204, 204, ${opacity})` // Set the faded color for non-slided range
        } else {
          this[chartType].datasets[0].backgroundColor[i] = 'lightgray' // Set the grey color for range outside the selected year range
        }
      }
      this.$nextTick(() => {
        if (!this[chartType].update) this[chartType].update = true
      })
    },
    updateChartBarColors() {
      const { chart } = this
      chart.labels.forEach((year, index) => {
        if (year < this.sliderStart || year > this.sliderEnd) {
          chart.datasets[0].backgroundColor[index] = 'lightgray'
          chart.datasets[0].borderColor[index] = 'lightgray'
        } else {
          chart.datasets[0].backgroundColor[index] = '#79B4DE'
          chart.datasets[0].borderColor[index] = '#79B4DE'
        }
      })
      chart.datasets[0].hoverBackgroundColor = new Array(
        chart.labels.length
      ).fill('#3C7EAD')
      // Use $nextTick to ensure the watcher is called
      this.$nextTick(() => {
        if (!chart.update) chart.update = true
      })
    },
    updateModalChartBarColors(startYear, endYear) {
      const { modalChart } = this
      modalChart.labels.forEach((year, index) => {
        if (year < startYear || year > endYear) {
          modalChart.datasets[0].backgroundColor[index] = 'lightgray'
          modalChart.datasets[0].borderColor[index] = 'lightgray'
        } else {
          modalChart.datasets[0].backgroundColor[index] = '#79B4DE'
          modalChart.datasets[0].borderColor[index] = '#79B4DE'
        }
      })
      // Use $nextTick to ensure the watcher is called
      this.$nextTick(() => {
        if (!modalChart.update) modalChart.update = true
      })
    },
    handleGraphClick(event, elements) {
      if (
        elements.length > 0 &&
        this.sliderMin &&
        this.sliderMax &&
        this.sliderMin != this.sliderMax
      ) {
        // not to do any functionality when there is only one bar
        this.showModalResetTimeline = true
        this.graphClicked = true
        const elementSelected = elements[0]
        const index = elementSelected._index
        const selectedYear = this.chart.labels[index]
        this.startYearInput = null
        this.endYearInput = null
        //update timeline graph and slider handle
        this.transitionSlider(selectedYear, selectedYear)
        this.searchCustomDates(selectedYear, selectedYear)
        registerMatomoEvent(
          'Search',
          'Filter - timeline click',
          'Timeline click'
        )
      }
    },
    handleModalGraphClick(event, elements) {
      if (
        elements.length > 0 &&
        this.sliderMin &&
        this.sliderMax &&
        this.sliderMin != this.sliderMax
      ) {
        this.showResults = true
        this.showModalResetTimeline = true
        this.modalGraphClicked = true
        const elementSelected = elements[0]
        const index = elementSelected._index
        this.chartModalSelectedYear = this.modalChart.labels[index]

        //update timeline graph and slider - enable smooth animation of slider handle change
        this.$refs.modalSlider.noUiSlider.set([
          this.chartModalSelectedYear,
          this.chartModalSelectedYear,
        ])
        this.modalChart.update = false
        this.updateModalChartBarColors(
          this.chartModalSelectedYear,
          this.chartModalSelectedYear
        )

        registerMatomoEvent(
          'Search',
          'Filter - big timeline click',
          'BigTimeline click'
        )
      }
    },
    expandChart() {
      const { chart, modalChart } = this
      modalChart.update = false
      this.modalVisible = true
      if (this.showResetTimeline) this.showModalResetTimeline = true
      // Copy the main chart data to the modal chart data
      const tempModalChart = deepClone({
        labels: chart.labels,
        datasets: chart.datasets,
      })
      modalChart.labels = tempModalChart.labels
      modalChart.datasets = tempModalChart.datasets

      this.$nextTick(() => {
        if (!modalChart.update) modalChart.update = true
        //create modalSlider
        this.showSlider('modalSlider')
      })
      registerMatomoEvent('Search', 'Expand timeline', 'xTimeline')
    },
    closeChart(minimize) {
      this.modalVisible = false
      this.showModalResetTimeline = false
      this.showResults = false
      this.isModalResetClicked = false
      // reset modal chart data
      this.modalChart.labels = []
      this.modalChart.datasets = []
      if (minimize)
        registerMatomoEvent(
          'Search',
          'Reduce big timeline',
          'Reduce big timeline'
        )
      else if (!this.showResultsFromModal)
        registerMatomoEvent(
          'Search',
          'Close (X) big timeline',
          'Close BigTimeline'
        )
      this.showResultsFromModal = false // to handle matomo
    },
    //timeline chart methods - end

    showModalResults() {
      this.showResultsFromModal = true
      this.showResultsFromModalClicked = true
      if (this.modalGraphClicked || this.modalSliderChange) {
        this.showResetTimeline = true
        if (this.modalGraphClicked) {
          //update timeline graph and slider handle
          this.transitionSlider(
            this.chartModalSelectedYear,
            this.chartModalSelectedYear
          )
          this.searchCustomDates(
            this.chartModalSelectedYear,
            this.chartModalSelectedYear
          )
        } else if (this.modalSliderChange) {
          //update timeline graph and slider handle
          this.transitionSlider(
            this.modalHandleValueStart,
            this.modalHandleValueEnd
          )
          this.searchCustomDates(
            this.modalHandleValueStart,
            this.modalHandleValueEnd
          )
        }
      } else if (this.isModalResetClicked) {
        this.resetTimeline()
      }
      this.showResults = false
      this.closeChart()
      registerMatomoEvent(
        'Search',
        'See results from big timeline',
        'Results BigTimeline'
      )
    },
    async resetTimeline() {
      this.showResetTimeline = false
      this.showModalResetTimeline = false
      // clear entered custom dates and date filters if selected
      this.clearCustomDates()
      // remove any checked filters for Date published Type
      delete this.checkedFilters['Date published']
      this.radioSelection = null
      //update timeline graph and slider - * changing the sequence of code as this will affect the animation of slider handles *
      this.transitionSlider(this.sliderMin, this.sliderMax)
      if (!this.showResultsFromModal)
        // handle matomo on modal reset click to prevent registering for main chart
        registerMatomoEvent('Search', 'Reset timeline', 'Reset timeline')

      const { query } = this
      // let newQuery = query
      let newQuery = this.queryWithoutBaseQuery //CIT-9506
      newQuery = newQuery.replace(/ AND \(FIRST_PDATE:([^)])+TO([^)])+\)/g, '')
      if (this.isqueryWithoutBasequery)
        // remove FIRST_PDATE from the query without baseQuery and update the query - CIT-9506
        newQuery = this.baseQuery + ' ' + newQuery.trim() //CIT-9506

      // await asynchronously while slider animates
      await new Promise((resolve) => setTimeout(resolve, 500)) // Introduce a delay to make the animated slider handle movement visible
      if (query !== newQuery) this.changeQuery(newQuery)
    },
    resetModalTimeline() {
      this.showModalResetTimeline = false
      // reset "graph click/ sider drag" if reset is clicked
      this.modalGraphClicked = false
      this.modalSliderChange = false
      this.isModalResetClicked = true
      this.showResults = true

      //reset timeline graph and slider - enable smooth animation of slider handle change
      this.$refs.modalSlider.noUiSlider.set([this.sliderMin, this.sliderMax])
      // update the modal bar chart colors to initial values
      this.modalChart.update = false
      this.updateModalChartBarColors(this.sliderMin, this.sliderMax)

      registerMatomoEvent('Search', 'Reset big timeline', 'Reset BigTimeline')
    },
    // Histogram search feature methods - end

    checkMultipleNestedFilters() {
      if (
        this.checkedFilters['Type'] &&
        this.checkedFilters['Type'].length === 2 &&
        Object.keys(this.checkedSubFilters).length
      ) {
        return true
      } else return false
    },
  },
}
</script>
<template>
  <div id="search-results--filter">
    <template v-for="(name, index) in Object.keys(filters)">
      <loading v-if="loading[name]" :key="name + 'loading'" />
      <fieldset v-show="!loading[name]" :key="name">
        <legend>
          <div
            :id="
              'search-results--filter--subSection-' +
              name.replace(/[^a-zA-Z]/g, '-')
            "
            class="h4"
            style="margin: auto"
          >
            {{ name }}
            <tooltip
              v-if="name === 'Type' || name === 'Free full text access'"
              boundaries="#search-page-content"
              :placement="mobile ? 'bottom' : 'right'"
              width="340px"
              @show="showTooltip(name)"
            >
              <i slot="trigger" class="far fa-question-circle" />
              {{
                name === 'Type'
                  ? 'To limit your search to specific article types, select one or more of the filters below:'
                  : 'Free full text articles accessible in, or from Europe PMC may be filtered by:'
              }}
              <ol v-if="name === 'Type'">
                <li>
                  <b>Research articles:</b> articles presenting the results of
                  experimental research.
                </li>
                <li>
                  <b>Reviews:</b> reviews on a topic covering a broad set of
                  previously published articles.
                </li>
                <li>
                  <b>Preprints:</b> research articles that have not yet
                  undergone formal peer-review and have been submitted to public
                  preprint servers.
                  <ol style="list-style-type: disc">
                    <li>
                      <b>Reviewed:</b> preprints that have reviews,
                      recommendations or commentary from expert sources.
                    </li>
                    <li>
                      <b>Journal published:</b> journal published articles that
                      have been connected to their previously posted preprints.
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Books & documents:</b> The NCBI bookshelf's collection of
                  books, reports, and databases, etc., in the areas of biology,
                  medicine, and the life sciences.
                </li>
              </ol>
              <ol v-else>
                <li>
                  <b>Full text in Europe PMC:</b> All articles that are freely
                  available to read within Europe PMC. To filter for open access
                  license types, see
                  <a href="/advancesearch">Advanced search</a>.
                </li>
                <li>
                  <b>Link to free full text:</b> All articles with a known link
                  to a free and legal copy of the full text, but that are not
                  available to read within Europe PMC.
                </li>
              </ol>
            </tooltip>
          </div>
        </legend>
        <!-- Histogram search feature start -->
        <div v-if="name === 'Date published'" class="timeline">
          <!-- Reset Histogram -->
          <action
            v-show="showResetTimeline && isRangeValid"
            id="reset--timeline"
            @click.prevent="resetTimeline"
          >
            <span>Reset</span>
          </action>
          <!-- Timeline chart-->
          <div
            v-show="isRangeValid"
            :class="{
              'chart-box': true,
              'small-margin-top': showResetTimeline,
            }"
          >
            <span v-if="!mobile" class="expand-icon" @click="expandChart">
              <i class="fa fa-expand"></i>
            </span>
            <chart
              v-show="chart.update && chart.labels.length"
              id="histogram"
              :type="chart.type"
              :labels="chart.labels"
              :datasets="chart.datasets"
              :options="Object.assign({}, sharedChartOptions, chartOptions)"
              :update="chart.update"
              aria-label="Date timeline histogram"
            />
          </div>
          <!-- Slider for the Timeline chart -->
          <div v-show="isRangeValid && isSliderRangeValid" class="slider">
            <div ref="slider"></div>
          </div>
        </div>
        <!-- Histogram search feature end -->
        <ul>
          <li
            v-for="(filter, index) in filters[name]"
            v-show="
              !filter.parent ||
              (filter.parent && filters[name][filter.parent.index].checked)
            "
            :key="filter.id"
            :class="filter.parent ? 'sub-filter' : ''"
          >
            <input
              v-if="name === 'Date published'"
              :id="'search-results--filter--' + filter.id"
              v-model="radioSelection"
              :name="name"
              type="radio"
              :value="index"
              :disabled="filter.name !== 'Custom date filter' && !filter.count"
              @change="onContentFilterChange"
            />
            <input
              v-else
              :id="'search-results--filter--' + filter.id"
              v-model="filter.checked"
              :name="name"
              type="checkbox"
              :value="index"
              :disabled="filter.name !== 'Custom date filter' && !filter.count"
              @change="onContentFilterChange"
            />
            <label
              v-if="filter.name !== 'Custom date filter'"
              style="padding-left: 0.5rem"
              :for="'search-results--filter--' + filter.id"
              :class="{ disabled: !filter.count }"
              >{{ filter.label }}
              <span :class="mobile ? 'small' : 'extra-small'"
                >({{ filter.count.toLocaleString() }})</span
              >
            </label>
            <label
              v-else
              style="padding-left: 0.5rem"
              :for="'search-results--filter--' + filter.id"
              class="custom-dates"
            >
              <label class="alt-text" for="search-results--filter--dateFrom"
                >Start year</label
              >
              <input
                id="search-results--filter--dateFrom"
                :value="startYearInput !== null ? startYearInput : startYear"
                type="text"
                placeholder="YYYY"
                maxlength="4"
                @input="onCustomInputStart"
                @keyup.enter="searchCustomDates(null, null)"
              />
              to
              <label class="alt-text" for="search-results--filter--dateTo"
                >End year</label
              >
              <input
                id="search-results--filter--dateTo"
                :value="endYearInput !== null ? endYearInput : endYear"
                type="text"
                placeholder="YYYY"
                maxlength="4"
                @input="onCustomInputEnd"
                @keyup.enter="searchCustomDates(null, null)"
              />
              <action
                v-show="showSearchIcon"
                id="search-results--filter–searchButton"
                @click="searchCustomDates(null, null)"
              >
                <i slot="icon" class="fas fa-search" />
              </action>
              <notification v-if="errorMessage" notification-style="error">
                {{ errorMessage }}
              </notification>
            </label>
          </li>
        </ul>
        <hr
          v-if="index !== Object.keys(filters).length - 1"
          style="margin-top: 16px"
        />
      </fieldset>
    </template>
    <!-- Expanded Histogram search start -->
    <modal
      v-if="modalVisible"
      id="timeline-modal"
      class="large"
      :close-when-clicking-outside="true"
      @close="closeChart"
    >
      <template slot="title">Date published</template>
      <div class="modal-histogram-icons">
        <span style="cursor: pointer" @click="closeChart(true)"
          ><i class="fas fa-compress"></i
        ></span>
        <div class="modal-histogram-actions">
          <action
            v-show="showModalResetTimeline"
            id="reset--timeline"
            @click.prevent="resetModalTimeline"
          >
            <span>Reset</span>
          </action>
          <button
            v-if="showResults"
            class="secondary"
            @click="showModalResults"
          >
            Go to results
          </button>
        </div>
      </div>
      <!-- Expanded Timeline chart -->
      <chart
        v-show="modalChart.update && modalChart.labels.length"
        id="histogramModal"
        class="chart-box-modal"
        :type="modalChart.type"
        :labels="modalChart.labels"
        :datasets="modalChart.datasets"
        :options="Object.assign({}, sharedChartOptions, modalChartOptions)"
        :update="modalChart.update"
        :name="modalChart.name"
        aria-label="Date timeline histogram"
      />
      <!-- Slider for the expanded Timeline chart -->
      <div
        v-show="isRangeValid && isSliderRangeValid"
        :class="{ slider, 'modal-slider': modalVisible }"
      >
        <div ref="modalSlider"></div>
      </div>
    </modal>
    <!-- Expanded Histogram search end -->
  </div>
</template>
<style lang="scss">
#search-results--filter {
  .h3 {
    margin: ($base-unit * 4) auto;
  }
  .h4 {
    margin: 0 auto;
  }
  fieldset {
    display: block;
    margin-bottom: $base-unit * 4;
    &:last-of-type {
      margin-bottom: $base-unit * 2;
    }
  }
  .action {
    font-size: $base-unit * 3.75;
  }
  .reset-action {
    margin-left: $base-unit * 4;
  }
  ul {
    margin-top: 0;
    padding: 0;
    list-style-type: none;
    &:last-of-type {
      margin-bottom: 0;
    }
    li {
      display: flex;
      align-items: baseline;
    }
  }
  li {
    input[type='checkbox'] {
      margin-left: 0;
      margin-right: $base-unit * 2;
      vertical-align: $base-unit / 2;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    &.sub-filter {
      margin-left: $base-unit * 4;
    }
  }
  .custom-dates {
    input[type='text'] {
      width: $base-unit * 13;
      height: $base-unit * 8;
      margin-right: $base-unit;
      border-radius: 2px;
      &:last-of-type {
        margin-left: $base-unit;
        margin-right: $base-unit * 2;
      }
    }
    .notification {
      margin-top: $base-unit;
    }
  }
  @media screen and (max-width: $breakpoint-extra-small) {
    margin: 0 auto;
    padding-bottom: $base-unit * 4;
    width: $width-x;
    font-size: $base-unit * 4;
    line-height: $base-unit * 6.5;
    h4 {
      font-size: $base-unit * 4.5;
      margin: ($base-unit * 5) auto ($base-unit * 2);
    }
    li {
      input[type='checkbox'] {
        vertical-align: baseline;
      }
    }
    .action {
      font-size: $base-unit * 4;
      line-height: $base-unit * 6.5;
    }
  }
  //styles used for main timeline chart - start
  .timeline {
    padding-bottom: $base-unit * 2.5;
    .chart-box {
      border: 0.5px solid lightgrey;
      border-bottom: 3px solid lightgrey;
      position: relative;
      height: $base-unit * 36.5;
      margin-top: 4 * $base-unit;
      cursor: pointer;

      @media screen and (min-width: 1600px) {
        transition: height 0.3s ease;
        height: 128px;
      }

      @media screen and (max-width: $breakpoint-med) {
        transition: height 0.3s ease;
        height: $base-unit * 31.5;
      }

      @media screen and (max-width: $breakpoint-small) {
        transition: height 0.3s ease;
        height: $base-unit * 30.75;
      }

      @media screen and (max-width: $breakpoint-extra-small) {
        transition: height 0.3s ease;
        height: auto;
      }
    }
    .small-margin-top {
      margin-top: 0;
    }
    .expand-icon {
      position: relative;
      transform: translateY(-50%);
      margin-left: 3px;
    }
  }
  //styles used for main timeline chart - end

  //styles used for both main timeline chart and expanded modal timeline chart - start
  #reset--timeline {
    display: flex;
    margin-top: $base-unit * 2;
    font-size: $base-unit * 4;
    transition-delay: 0s;
    opacity: 1;
  }
  #reset--timeline span {
    margin-left: auto;
  }
  //styles used for both main timeline chart and expanded modal timeline chart - end

  //styles for slider
  .slider {
    margin: -0.8rem 0 0;
    position: relative;
    width: 98.95%;
    left: 2.2px;
    .noUi-target {
      margin: 0 auto;
      height: 2rem;
      border: none;
      box-shadow: none;
      background-color: transparent;
      width: 100%;
    }
    .noUi-base {
      height: 2rem;
    }
    .noUi-connects {
      overflow: visible;
    }
    .noUi-connect {
      position: absolute;
      top: 50%;
      height: 1.5rem;
      margin-top: -1rem;
      background-color: transparent;
    }
    .noUi-connect::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 44%;
      height: 0.25rem;
      background-color: #3c7ead;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    .noUi-origin {
      top: 50%;
    }

    .noUi-horizontal .noUi-handle {
      right: 0;
    }

    .noUi-handle {
      border: 0.23rem solid #3c7ead;
      width: 3px;
      height: 13px;
      border-radius: 0;
      top: -4px;
      transition: all 0.3s ease-in-out;
      cursor: grab;
      cursor: -webkit-grab;
      -webkit-transform: translate(50%, -50%);
      transform: translate(50%, -50%);

      .noUi-tooltip {
        bottom: auto;
        top: 100%;
        border: none;
        font-size: 1.4rem;
        -webkit-transform: none;
        transform: none;
        width: 3.4rem;
        margin-top: 0.4rem;
        margin-bottom: -0.2rem;
        padding: 0;
        line-height: 1;
        background-color: transparent;
      }
    }

    .noUi-handle::before {
      content: none;
      display: none;
    }

    .noUi-handle::after {
      content: none;
      display: none;
    }

    .noUi-handle:active {
      width: 6px;
      border: 2px solid #3c7ead;
      border-style: dashed;
      transition: all 0.3s ease-in-out;
    }

    .noUi-handle:hover {
      cursor: grabbing;
      cursor: -webkit-grabbing;
    }

    .noUi-handle.noUi-handle-lower {
      .noUi-tooltip {
        margin-left: -1.7rem;
      }
      .noUi-tooltip.intersected:after {
        content: ' - ' !important;
        /* Add the '-' */
        color: #3c7ead;
        font-weight: bolder;
        position: relative;
      }
    }
    .noUi-handle.noUi-handle-upper {
      .noUi-tooltip {
        margin-left: -1.7rem;
      }
      .noUi-tooltip.hide {
        display: none;
      }
    }
    .noUi-state-tap .noUi-connect,
    .noUi-state-tap .noUi-origin {
      -webkit-transition: transform 500ms;
      transition: transform 500ms;
    }
  }
  .noUi-horizontal .noUi-handle {
    left: auto;
  }
  //styles for expand timeline modal
  #timeline-modal {
    .chart-box-modal {
      height: 76.25 * $base-unit;
      border: 0.5px solid lightgrey;
      border-bottom: 3px solid lightgrey;
      position: relative;
      margin-top: 3 * $base-unit;
      cursor: pointer;
    }
    .modal-histogram-icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .modal-histogram-actions {
      display: flex;
      gap: 10px;
    }
    .modal-slider {
      width: 99.3%;
      left: 2.6px;
    }
  }
}
</style>
